import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Login from "./controller/Login";
import Dashboard from "./controller/Dashboard";
import PrivateRoute from "./access/privateRoute";
import AddTraining from "./controller/AddTraining";
import EditTraining from "./controller/EditTraining";
import Company from "./controller/Company";
import Insight from "./controller/Insight";
import Video from "./controller/Video";
import AddVideo from "./controller/AddVideo";
function App() {
  localStorage.setItem("currentSelection", "Companies");
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/training" element={<AddTraining />} />
          <Route path="/edit" element={<EditTraining />} />
          <Route path="/company/:id" element={<Company />} />
          <Route path="/insight" element={<Insight />} />
          <Route path="/360video" element={<Video />} />
          <Route path="/addVideo" element={<AddVideo />} />
        </Route>
        <Route path="/*" element={<Navigate to="/" />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
