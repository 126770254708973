import React, { useEffect, useState } from "react";
import Sidebar from "./SideBar";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { Dropdown } from "primereact/dropdown";
import "./AddTraining.css";
import { Checkbox } from "primereact/checkbox";
import "react-dropzone-uploader/dist/styles.css";
import DropzoneUploader from "react-dropzone-uploader";
import { ProgressSpinner } from "primereact/progressspinner";
import FormData from "form-data";
import { InputTextarea } from "primereact/inputtextarea";

function AddTraining() {
  const [currentSelection, setCurrentSelection] = useState("360 Video");
  const [checked, setChecked] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedTrainingTitle, setSelectedTrainingTitle] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [customName, setCustomName] = useState("");
  const [customDescriprion, setCustomDescription] = useState("");
  const [customVersion, setCustomVersion] = useState("");
  const [isValidCustomVersion, setIsValidCustomVersion] = useState(true);
  const [isUloading, setIsUploading] = useState(false);
  const [isValidCustomname, setIsValidCustomname] = useState(true);
  const [responseVR, setResponseVR] = useState(null);
  const [isValidCustomDescription, setIsValidCustomDescription] =
    useState(true);
  const [isValidFile, setIsValidFile] = useState(true);
  const [result, setResult] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    localStorage.setItem("currentSelection", currentSelection);
  }, [currentSelection]);

  const fetchData = async () => {
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const responseVR = await axios.get(
      process.env.REACT_APP_BACKEND_LINK + "/vr/get/trainings",
      config
    );

    setResponseVR(responseVR.data.data);
    console.log(responseVR.data.data);
  };
  const resetForm = () => {
    setSelectedTrainingTitle(null);
    setSelectedCompany(null);
    setCustomName("");
    setCustomDescription("");
    setIsValidCustomname(true);
    setIsValidCustomDescription(true);
    setIsValidFile(true);
    setIsLoading(false);
    setFile(null);
    setIsUploading(false);
    setResult(null);
    setChecked(false);
  };

  const handleAddTraining = async () => {
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("token");

    let isValidInput = true;
    if (!file) {
      setIsValidFile(false);
      toast.error("Please select a file.");
      isValidInput = false;
    }
    if (!customName) {
      setIsValidCustomname(false);
      toast.error("Please enter a custom name.");
      isValidInput = false;
    }
    if (!customVersion) {
      setIsValidCustomVersion(false);
      toast.error("Please enter a custom version.");
      isValidInput = false;
    }
    if (!customDescriprion) {
      setIsValidCustomDescription(false);
      toast.error("Please enter a custom description.");
      isValidInput = false;
    }
    if (!isValidInput) {
      return;
    }

    const data = new FormData();
    console.log(file);
    data.append("file", file);
    data.append("customname", customName);
    data.append("version", customVersion);
    data.append("description", customDescriprion);

    console.log(data);

    //data.append
    setIsUploading(true);
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
      },
    };

    console.log(customName);
    console.log(customDescriprion);
    console.log(file);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_LINK}/vr/upload/training/default`,
        data,
        config
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Company added successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          console.log("Training added successfully.");
        }
        setIsUploading(false);
        resetForm();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.error("This email already registered.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          console.log("This email already registered.");
        } else if (error.status === 402) {
          toast.error("Please provide a valid email address.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          console.log("Please provide a valid email address.");
        } else if (error.status === 403) {
          toast.error("Training already exists.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          console.log("Training already exists.");
        } else if (error.status === 400) {
          toast.error("Error status 400. Please contact support");
          console.log("Error status 400.");
        } else {
          toast.error("An error occurred while adding the company.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          console.log("An error occurred while adding the company.");
        }
        setIsUploading(false);
        resetForm();
      });
  };

  const handleCustomNameChange = (event) => {
    setCustomName(event.target.value);
    setIsValidCustomname(true);
  };
  const handleCustomVersionChange = (event) => {
    const value = event.target.value;

    // Validate the input using a regular expression
    if (/^\d*$/.test(value)) {
      setCustomVersion(event.target.value);
      setIsValidCustomVersion(true);
    } else {
      setIsValidCustomVersion(false);
      toast.error("Please enter a valid version.");
    }
  };

  const handleCustomDescriptionChange = (event) => {
    setCustomDescription(event.target.value);
    setIsValidCustomDescription(true);
  };

  const onUpload = (file) => {
    setIsLoading(true);
    console.log(file.file);
    // upload file logic here...
    setFile(file.file);
    setIsLoading(false);
  };

  const handleSelectionChange = (selection) => {
    setCurrentSelection(selection);
  };

  useEffect(() => {
    if (currentSelection === "Companies") {
      navigate("/");
    } else if (currentSelection === "Plans") {
      navigate("/plans");
    }
  }, [currentSelection, navigate]);

  const Preview = ({ meta, fileWithMeta: { remove } }) => {
    const handleRemove = (event) => {
      event.stopPropagation();
      remove();
    };

    return (
      <div className="preview">
        <div className="flex flex-row gap-2 align-items-center">
          <i className="pi pi-file" style={{ fontSize: "1.2rem" }}></i>
          <p>{meta.name}</p>
          <button
            className="pi pi-trash "
            style={{
              border: "none",
              background: "none",
              marginLeft: "200px",
              fontSize: "1.2rem",
            }}
            onClick={handleRemove}
          ></button>
        </div>
      </div>
    );
  };

  return (
    <div style={{ display: "flex", margin: 0 }}>
      <div
        style={{
          width: "230px",
          height: "100vh",
          backgroundColor: "#f2f4f8",
          padding: "10px",
          margin: 0,
          zIndex: 1100,
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        <Sidebar
          UIselection="360 Video"
          onSelectionChange={handleSelectionChange}
        />
      </div>
      <div style={{ width: "80%", padding: "10px", marginLeft: "240px" }}>
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Button
              icon="pi pi-arrow-left"
              className="dot-button"
              style={{
                background: "none",
                border: "none",
                boxShadow: "none",
                marginRight: "-15px",
                color: "rgba(46, 58, 75, 0.7)",
              }}
              onClick={() => {
                navigate("/360video", { state: { status: "360 Video" } });
              }}
            ></Button>
            <h2
              id="title"
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                marginLeft: "10px",
                color: "rgba(46, 58, 75, 0.7)",
              }}
            >
              {currentSelection} /
            </h2>
            <h2
              id="title"
              style={{ margin: 0, whiteSpace: "nowrap", marginLeft: "41px" }}
            >
              Add Video
            </h2>
          </div>
          <div
            className="flex flex-column gap-2"
            style={{ marginTop: "35px", flex: 1 }}
          >
           
            <div className="flex flex-row gap-2">
              <div
                className="flex flex-column gap-2"
                style={{ marginTop: "35px", flex: 1 }}
              >
                {checked ? (
                  <div
                    className="flex flex-column gap-2"
                    style={{ marginLeft: "0px" }}
                  >
                    <label className="font-text" htmlFor="training title">
                      Video Title
                    </label>
                    <Dropdown
                      value={selectedTrainingTitle}
                      id="training-title"
                      aria-describedby="training-title-help"
                      onChange={async (e) => {
                        setSelectedTrainingTitle(e.value);
                        try {
                          console.log(e.value);
                          
                          console.log(e.value.version);
                          setCustomVersion(e.value.version);
                          setCustomName(e.value.name);
                          setCustomDescription(e.value.description);

                          const gg = e.value.platform;

                          console.log(gg);

                          const defaults = [
                            { name: "pico" },
                            { name: "quest" },
                            { name: "windows" },
                          ];
                          const filteredPlatforms = defaults.filter(
                            (item) =>
                              !gg.some(
                                (def) =>
                                  def.platform.toLowerCase() ===
                                  item.name.toLowerCase()
                              )
                          );
                          console.log(filteredPlatforms);
                          setResult(filteredPlatforms);
                        } catch (error) {
                          console.error("Error fetching companies:", error);
                        }
                      }}
                      options={responseVR}
                      optionLabel="name"
                      placeholder="Select a training title"
                      style={{ width: "100%" }}
                    />
                  </div>
                ) : (
                  <div
                    className="flex flex-column gap-2"
                    style={{ marginLeft: "0px" }}
                  >
                    <label className="font-text" htmlFor="training title">
                      Training Title
                    </label>
                    <InputText
                      id="training-title"
                      onChange={handleCustomNameChange}
                      aria-describedby="training-title-help"
                      className={isValidCustomname ? "" : "error"}
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
                <div
                  className="flex flex-row gap-2"
                  style={{ marginTop: "41px" }}
                >
                  <div
                    className="flex flex-column gap-2"
                    style={{ marginTop: "0px", flex: 1 }}
                  >
                    <label className="font-text" htmlFor="subject">
                      Subject
                    </label>
                    <InputText
                      id="subject"
                      aria-describedby="subject-help"
                      style={{ width: "100%" }}
                      disabled={checked}
                    />
                  </div>
                 
                </div>
              </div>
              <div
                className="flex flex-column gap-2"
                style={{ marginTop: "35px", marginLeft: "30px", flex: 1 }}
              >
                <label className="font-text" htmlFor="description title">
                  Description
                </label>
                <InputTextarea
                  id="description title"
                  autoResize="false"
                  rows={7}
                  onChange={handleCustomDescriptionChange}
                  aria-describedby="description title-help"
                  className={isValidCustomDescription ? "" : "error"}
                  style={{ width: "100%", height: "165px" }}
                  disabled={checked}
                />
              </div>
            </div>          
            <div
              className="flex flex-column gap-2"
              style={{ marginTop: "35px" }}
            >
              <div className="flex flex-row gap-2">
                <label className="font-text" htmlFor="Upload">
                  Upload
                </label>
              </div>
              <div className="flex flex-row gap-2">
                <DropzoneUploader
                  onChangeStatus={onUpload}
                  accept=".pak"
                  maxFiles={1}
                  multiple={false}
                  canCancel={false}
                  inputContent={
                    <div>
                      <div
                        className="flex flex-raw gap-2"
                        style={{ marginLeft: "12px" }}
                      >
                        <i
                          className="pi pi-upload"
                          style={{
                            fontSize: "1.2rem",
                            color: "#005DFF",
                            fontWeight: "bold",
                          }}
                        ></i>
                        <div className="gidota">Upload video</div>
                      </div>
                      <div className="gadost">
                        or drag & drop your file here
                      </div>
                    </div>
                  }
                  PreviewComponent={Preview}
                  styles={{
                    dropzone: {
                      minHeight: 50,
                      border: isValidFile
                        ? "2px dashed rgb(0,0,0,0.5)"
                        : "2px dashed red",
                      borderRadius: 5,
                      margin: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 10,
                      width: "100%",
                      height: "80px",
                      transition: "none",
                    },
                    inputLabel: {
                      color: "#333",
                      fontSize: 14,
                    },
                    input: {
                      display: "none",
                    },
                    preview: {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 10,
                      position: "static",
                      transition: "none",
                    },
                    previewImage: {
                      maxHeight: 200,
                      maxWidth: "100%",
                    },
                    slider: {
                      display: "none",
                    },
                    dropzoneActive: {
                      borderStyle: "solid",
                      backgroundColor: "#eee",
                    },
                    previewContainer: {
                      transition: "none !important",
                    },
                  }}
                />
                {isLoading ? (
                  <div className="loading-screen">
                    <ProgressSpinner />
                  </div>
                ) : (
                  <div style={{ marginTop: "20px" }}>
                    {file ? (
                      <div className="flex flex-col items-center"></div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
            <div
              className="flex flex-row gap-2"
              style={{ marginTop: "35px", justifyContent: "center" }}
            >
              <Button
                label="Back"
                className="custom-buttonq"
                style={{ padding: "4px 8px" }}
                onClick={() => {
                  navigate("/", { state: { status: "VR Training" } });
                }}
              />
              <Button
                label="Add video"
                className="custom-button"
                style={{ padding: "4px 8px" }}
                onClick={handleAddTraining}
              />
              {isUloading && (
                <ProgressSpinner style={{ width: "50px", height: "50px" }} />
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default AddTraining;
