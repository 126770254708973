import React, { useEffect, useState, useRef } from "react";
import Sidebar from "./SideBar";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { Dropdown } from "primereact/dropdown";
import "./Video.css";
import { Checkbox } from "primereact/checkbox";
import "react-dropzone-uploader/dist/styles.css";
import DropzoneUploader from "react-dropzone-uploader";
import { ProgressSpinner } from "primereact/progressspinner";
import FormData from "form-data";
import { InputTextarea } from "primereact/inputtextarea";
import { Card } from "primereact/card";
import { OverlayPanel } from 'primereact/overlaypanel';
import "primereact/resources/themes/saga-blue/theme.css"; // подключение темы
import "primereact/resources/primereact.min.css"; // основные стили
import "primeicons/primeicons.css"; // иконки PrimeReact

function Video() {
  const [currentSelection, setCurrentSelection] = useState("360 Video");
  const [rowData, setRowData] = useState(null);
  const [videos, setVideos] = useState([]);
  const overlayPanelRef = useRef(null);

  

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    localStorage.setItem("currentSelection", currentSelection);
  }, [currentSelection]);

  const fetchData = async () => {
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_LINK}/get/videos/forAdmin`,
        config
      );
      setVideos(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectionChange = (selection) => {
    setCurrentSelection(selection);
  };
  const overlayPanelContent = (
    <div className="button-box">
      <button  className="button-panel">
        Edit
      </button>
      <button  className="button-panel">
        Unassign
      </button>
      <button  className="button-panel">
        Update chunk
      </button>
      <button  className="button-panel">
        Add poster
      </button>
      <button  className="button-panel">
        Assign
      </button>
      <button  className="button-panel">
        Delete
      </button>
    </div>
  );

  const handleButtonClick = (event, video) => {
    setRowData(video); // Сохраняем данные видео в состоянии
    overlayPanelRef.current.toggle(event); // Открываем OverlayPanel
  };

  useEffect(() => {
    if (currentSelection === "Companies") {
      navigate("/");
    } else if (currentSelection === "Plans") {
      navigate("/plans");
    }
  }, [currentSelection, navigate]);
  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={rowData.image}
        alt={rowData.title}
        style={{ width: "100px", height: "auto" }}
      />
    );
  };

  

  return (
    <div style={{ display: "flex", margin: 0 }}>
      <div
        style={{
          width: "230px",
          height: "100vh",
          backgroundColor: "#f2f4f8",
          padding: "10px",
          margin: 0,
          zIndex: 1100,
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        <Sidebar
          UIselection="360 Video"
          onSelectionChange={handleSelectionChange}
        />
      </div>
      <div style={{ width: "80%", padding: "10px", marginLeft: "240px" }}>
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <h2 id="title" style={{ margin: 0, whiteSpace: "nowrap" }}>
              {currentSelection}
            </h2>
            <div>
              <Button
                label="Add Video"
                icon="pi pi-plus"
                className="custom-button"
                onClick={() => {
                  navigate("/addVideo");
                }}
              />
            </div>
          </div>
        </div>
        <div className="video-grid">
          {videos.map((video) => (
            <div key={video.id} className="video-card">
              <div className="video-image">
                <img src={video.image} alt={video.title} />
                <div className="video-duration">{video.duration}</div>
              </div>
              <div className="video-info">
                <p className="category">{video.category}</p>
                <h3>{video.title}</h3>
              </div>
              <div className="button-con">
                <Button
                  icon="pi pi-ellipsis-v"
                  className="dot-button"
                  style={{
                    background: "none",
                    border: "none",
                    boxShadow: "none",
                    color: "rgba(46, 58, 75, 0.5)",
                  }}
                   onClick={(event) => handleButtonClick(event, video)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <ToastContainer />
        <OverlayPanel ref={overlayPanelRef}>
            {overlayPanelContent}
        </OverlayPanel>

    </div>
  );
}

export default Video;
